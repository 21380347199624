import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-hampton-virginia.png'
import image0 from "../../images/cities/scale-model-of-casemate-museum-of-fort-monroe-in-hampton-virginia.png"
import image1 from "../../images/cities/scale-model-of-fort-wool-in-hampton-virginia.png"
import image2 from "../../images/cities/scale-model-of-fort-monroe-national-monument-in-hampton-virginia.png"
import image3 from "../../images/cities/scale-model-of-buckroe-beach-and-park-in-hampton-virginia.png"
import image4 from "../../images/cities/scale-model-of-virginia-air-&-space-center-in-hampton-virginia.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Hampton'
            state='Virginia'
            image={image}
            lat='37.048'
            lon='-76.2971'
            attractions={ [{"name": "Casemate Museum of Fort Monroe", "vicinity": "20 Bernard Rd, Hampton", "types": ["museum", "point_of_interest", "establishment"], "lat": 37.0023942, "lng": -76.31006109999998}, {"name": "Fort Wool", "vicinity": "Rip Raps Island, Norfolk", "types": ["point_of_interest", "establishment"], "lat": 36.9866957, "lng": -76.30130409999998}, {"name": "Fort Monroe National Monument", "vicinity": "Fort Monroe", "types": ["park", "point_of_interest", "establishment"], "lat": 37.0040553, "lng": -76.3073197}, {"name": "Buckroe Beach and Park", "vicinity": "100 S 1st St, Hampton", "types": ["park", "point_of_interest", "establishment"], "lat": 37.0402512, "lng": -76.29080490000001}, {"name": "Virginia Air & Space Center", "vicinity": "600 Settlers Landing Rd, Hampton", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 37.02398700000001, "lng": -76.34429999999998}] }
            attractionImages={ {"Casemate Museum of Fort Monroe":image0,"Fort Wool":image1,"Fort Monroe National Monument":image2,"Buckroe Beach and Park":image3,"Virginia Air & Space Center":image4,} }
       />);
  }
}